var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-list-wrapper",class:{
    'hide-on-mobile': _vm.hideProductListMobile,
    'hide-on-desktop': _vm.hideProductListDesktop,
    [_vm.alignSingleItem]:
      _vm.alignSingleItem && _vm.items.length < 2 && _vm.products.length < 2,
  },style:(_vm.textColorCss)},[_c('div',{staticClass:"product-list",class:[
      _vm.mobilePadding ? 'mp--' + _vm.mobilePadding : '',
      _vm.mobileMargin ? 'mm--' + _vm.mobileMargin : '',
      _vm.desktopPadding ? 'dp--' + _vm.desktopPadding : '',
      _vm.desktopMargin ? 'dm--' + _vm.desktopMargin : '',
    ]},[_c('div',{staticClass:"list-header",class:{
        'hide-on-mobile': _vm.hideHeadlineMobile,
        'hide-on-desktop': _vm.hideHeadlineDesktop,
      }},_vm._l((_vm.headlines),function(headline,index){return _c('Headline',_vm._b({directives:[{name:"editable",rawName:"v-editable",value:(headline),expression:"headline"}],key:'headline' + index},'Headline',headline,false))}),1),_vm._v(" "),_c('div',{staticClass:"products grid",class:[
        'mc--' + _vm.mobileColumns,
        'dc--' + _vm.desktopColumns,
        'mg--' + _vm.mobileGridGutter,
        'dg--' + _vm.desktopGridGutter,
      ]},[(_vm.items && _vm.items.length)?[_vm._l((_vm.items),function(item,index){return [(item.component === 'ProductCardCentra')?_c('ProductCardCentra',_vm._b({directives:[{name:"editable",rawName:"v-editable",value:(item),expression:"item"}],key:index,staticStyle:{"padding-bottom":"16px"},attrs:{"position":index,"mobile-display-size":_vm.mobileDisplaySize,"desktop-display-size":_vm.desktopDisplaySize,"show-cart-icon":_vm.enableAddToCart}},'ProductCardCentra',item,false)):_c(item.component,_vm._b({directives:[{name:"editable",rawName:"v-editable",value:(item),expression:"item"}],key:index,tag:"component",staticClass:"hero",staticStyle:{"padding-bottom":"16px"},attrs:{"position":index,"mobile-size":"auto","desktop-size":"auto"}},'component',item,false))]})]:_vm._l((_vm.products),function(product,index){return _c('ProductCard',{key:_vm.categoryUri + '-' + product.product,attrs:{"product":product,"position":index + 1,"index":index,"mobile-display-size":_vm.mobileDisplaySize,"desktop-display-size":_vm.desktopDisplaySize,"show-cart-icon":_vm.enableAddToCart}})})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }