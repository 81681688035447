<template>
  <div
    class="product-list-wrapper"
    :class="{
      'hide-on-mobile': hideProductListMobile,
      'hide-on-desktop': hideProductListDesktop,
      [alignSingleItem]:
        alignSingleItem && items.length < 2 && products.length < 2,
    }"
    :style="textColorCss"
  >
    <div
      class="product-list"
      :class="[
        mobilePadding ? 'mp--' + mobilePadding : '',
        mobileMargin ? 'mm--' + mobileMargin : '',
        desktopPadding ? 'dp--' + desktopPadding : '',
        desktopMargin ? 'dm--' + desktopMargin : '',
      ]"
    >
      <div
        class="list-header"
        :class="{
          'hide-on-mobile': hideHeadlineMobile,
          'hide-on-desktop': hideHeadlineDesktop,
        }"
      >
        <Headline
          v-for="(headline, index) in headlines"
          :key="'headline' + index"
          v-editable="headline"
          v-bind="headline"
        />
      </div>
      <div
        class="products grid"
        :class="[
          'mc--' + mobileColumns,
          'dc--' + desktopColumns,
          'mg--' + mobileGridGutter,
          'dg--' + desktopGridGutter,
        ]"
      >
        <template v-if="items && items.length">
          <template v-for="(item, index) in items">
            <ProductCardCentra
              v-if="item.component === 'ProductCardCentra'"
              v-bind="item"
              :key="index"
              v-editable="item"
              :position="index"
              :mobile-display-size="mobileDisplaySize"
              :desktop-display-size="desktopDisplaySize"
              :show-cart-icon="enableAddToCart"
              style="padding-bottom: 16px"
            />
            <component
              :is="item.component"
              v-else
              v-bind="item"
              :key="index"
              v-editable="item"
              :position="index"
              mobile-size="auto"
              desktop-size="auto"
              class="hero"
              style="padding-bottom: 16px"
            />
          </template>
        </template>
        <template v-else>
          <ProductCard
            v-for="(product, index) in products"
            :key="categoryUri + '-' + product.product"
            :product="product"
            :position="index + 1"
            :index="index"
            :mobile-display-size="mobileDisplaySize"
            :desktop-display-size="desktopDisplaySize"
            :show-cart-icon="enableAddToCart"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ProductCard from '~/components/product-card/ProductCard'
export default {
  name: 'ProductListCentra',
  components: { ProductCard },
  props: {
    /**
     * This is the category id, from storyblok
     */
    categoryUri: {
      type: String,
      required: false,
      default: null,
    },
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
    mobileColumns: {
      type: String,
      required: false,
      default: '2',
    },
    mobilePadding: {
      type: String,
      required: false,
      default: 'none',
    },
    mobileMargin: {
      type: String,
      required: false,
      default: 'none',
    },
    mobileGridGutter: {
      type: String,
      required: false,
      default: 'small',
    },
    desktopColumns: {
      type: String,
      required: false,
      default: '5',
    },
    desktopPadding: {
      type: String,
      required: false,
      default: 'none',
    },
    desktopMargin: {
      type: String,
      required: false,
      default: 'none',
    },
    desktopGridGutter: {
      type: String,
      required: false,
      default: 'small',
    },
    pageName: {
      type: String,
      default: null,
    },
    headlines: {
      type: Array,
      required: false,
      default: () => [],
    },
    enableAddToCart: {
      type: Boolean,
      default: false,
    },
    hideHeadlineDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideHeadlineMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideProductListDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideProductListMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    alignSingleItem: {
      type: String,
      required: false,
      default: 'left',
    },
    textColor: {
      type: String,
      required: false,
      default: '#000000',
    },
    productsFromSearch: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isHomePage() {
      return (
        this.$route.path ===
        `/${this.$store.getters['frontend/currentSiteLangSlug']}`
      )
    },
    products() {
      if (this.productsFromSearch.length) {
        return this.productsFromSearch
      }
      return this.hasItemProducts
        ? [] // @TODO: get products from store
        : this.$store.getters['centra-product/getProductsByCategoryUriOrId'](
            this.categoryUri
          )
    },
    /**
     * Depending on the number of columns, we pass the 'mobileDisplaySize' and 'desktopDisplaySize' as optional
     * props into the child component where we can apply them on images to get optimized image sizes
     */
    mobileDisplaySize() {
      return parseInt(100 / this.mobileColumns) + 10 + 'vw'
    },
    desktopDisplaySize() {
      return parseInt(100 / this.desktopColumns) + 10 + 'vw'
    },
    textColorCss() {
      return { '--text-color': this.textColor }
    },
    hasItemProducts() {
      return !!this.items.find((item) => item.component === 'ProductCardCentra')
    },
    itemProductIds() {
      return this.items
        .filter((item) => item.component === 'ProductCardCentra')
        .map((item) => item.productId)
    },
  },
  watch: {
    categoryUri(oldVal) {
      if (oldVal !== '') {
        this.lookupProducts()
      }
    },
  },
  mounted() {
    if (!this.products.length) {
      this.lookupProducts()
    }
  },
  serverPrefetch() {
    if (!this.products.length && !this.isHomePage) {
      // Could have been loaded already from another component that uses the same
      // category
      return this.lookupProducts()
    }
  },
  methods: {
    lookupProducts() {
      return this.hasItemProducts
        ? this.lookupItemProducts()
        : this.lookupCategoryProducts()
    },
    lookupItemProducts() {
      return this.$store.dispatch(
        'centra-product/lookupProducts',
        this.itemProductIds
      )
    },
    lookupCategoryProducts() {
      return this.$store.dispatch(
        'centra-product/lookupProductsByCategoryUri',
        this.categoryUri
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.product-list-wrapper {
  .product-list {
    .products {
      .hero {
        width: 100%;
        height: 100%;
        &::v-deep {
          .ratio {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
          }

          .hero-content {
            .hero-button,
            .hero-button .button {
              min-width: 0;
              width: 100%;
            }
          }
        }
      }
      .product-card {
        ::v-deep {
          .details {
            color: var(--text-color);
          }
        }
      }
    }
  }
}

// Tablet (Portrait)
@media screen and (min-width: $tablet) {
  // ...
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .product-list-wrapper {
    .product-list {
      .products {
        .hero {
          @include aspect-ratio(3, 4);
          height: fit-content;
          padding-bottom: 0 !important;

          &::v-deep {
            .hero-content {
              padding: spacing('small');
            }
          }
        }
      }
    }

    &.center {
      .product-list .products {
        justify-content: center;
      }
    }

    &.right {
      .product-list .products {
        justify-content: flex-end;
      }
    }

    &.center,
    &.right {
      .product-list .products {
        display: flex !important;
      }

      .product-list .products.dc--2::v-deep .product-card {
        width: 50%;
      }

      .product-list .products.dc--3::v-deep .product-card {
        width: 33%;
      }

      .product-list .products.dc--4::v-deep .product-card {
        width: 25%;
      }

      .product-list .products.dc--5::v-deep .product-card {
        width: 20%;
      }
    }
  }
}

// Laptop
@media screen and (min-width: $laptop) {
  // ...
}
</style>
